import React from "react"
import MemoQuestContent from "../../components/MemoQuestContent"
import Landing from "../../templates/Landing"

interface Props {}

const MemoQuest: React.FC<Props> = () => {
  return (
    <Landing>
      <MemoQuestContent />
    </Landing>
  )
}

export default MemoQuest
